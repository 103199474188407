<template>
  <div class="template-file-management">
    <el-form class="zwx-form" key="msForm" :model="msForm" ref="msForm" label-position="right" @submit.native.prevent>
      <div class="edit-form">
        <el-form-item label="编码：" prop="templateSort" label-width="70px">
          <el-input class="zwx-input" style="height: 30px;width: 160px!important;" placeholder="请输入" v-model.trim="msForm.templateSort" clearable></el-input>
        </el-form-item>
        <el-form-item label="名称：" prop="templateName" label-width="70px">
          <el-input class="zwx-input" style="height: 30px;width: 160px!important;" placeholder="请输入" v-model.trim="msForm.templateName" clearable></el-input>
        </el-form-item>
        <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add">添加</el-button>
      </div>
    </el-form>
    <!-- 筛选结果展示 -->
    <el-table class="zwx-table" style="width: 100%" row-key="rid" key="resultForm" :data="dataList" stripe border tooltip-effect="light">
      <el-table-column prop="templateSort" label="编码" width="150" header-align="center" align="left"></el-table-column>
      <el-table-column prop="templateName" label="名称" width="240" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column label="模板文件" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <template v-if="$zwxBase.verifyIsNotBlank(scope.row.filePath)">
            <el-button class="zwx-button zwx-button-text-26" type="text" @click="openFilePreview(scope.row.templateName, scope.row.filePath)">查看</el-button>
          </template>
          <template v-else>
            /
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" header-align="left" align="left" fixed="right" :min-width="160">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-top-right" @click="edit(scope.row)">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" :class="'zwx-button-danger'" type="text" @click="$system.msgbox(scope.row, '提示', '确定要删除吗？', '确定', '取消', del)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <base-pagination  :parentPage="currentPage" :pageSize="pageSize" :total="total" @currentChange="search" />
    <!-- 弹窗 -->
    <base-dialog ref="msDialog" class="edit-dialog" :title="title" @determine="submit()" @cancel="cancelDialog" @close="closeDialog" :pagination="false" style="width: 436px!important;">
      <el-form ref="editForm" class="zwx-form edit-form" :model="editForm" :rules="rules">
        <div class="edit-row">
          <el-form-item label-width="60px" label="编码：" prop="templateSort">
            <el-input class="zwx-input" v-model="editForm.templateSort" style="width: 300px !important" maxlength="10" placeholder="请输入" clearable />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="60px" label="名称：" prop="templateName">
            <el-input class="zwx-input" v-model="editForm.templateName" style="width: 300px !important" maxlength="50" placeholder="请输入" clearable />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="60px" label="文件：" prop="annexList">
            <div style="display: flex">
              <file-upload ref="msPositive" @showFileList="clickPositive" @success="clickPositiveSuccess" @error="clickError()" accept=".pdf,.PDF,.doc,.DOC,.docx,.DOCX" :size="50 * 1024 * 1024" sizeInfo="50M">
                <div style="text-align: left;">
                  <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(editForm.annexList) || editForm.annexList.length < 1">
                    上传
                  </el-button>
                </div>
              </file-upload>
              <span class="show-file" v-if="editForm.annexList.length > 0">
              <span v-for="(item, index) in editForm.annexList" :key="index" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)">
                <span>
                  <a style="color:#194DFF !important;margin-left:12px;cursor:pointer;" @click="openFilePreview1(editForm.annexList, index)">
                    <i :class="item.fileIconShow" style="color:#316CF5!important"></i>
                    {{ item.fileName }}
                  </a>
                  <span class="el-upload-list__item check" :style="item.check ? 'margin-left:18px;display:none;' : 'margin-left:18px;cursor:pointer'"><i class="el-icon-upload-success el-icon-circle-check"></i></span>
                  <span class="check" :style="item.check ? 'margin-left:18px;cursor:pointer' : 'margin-left:18px;display:none;'"><i class="el-icon-close" @click="deletionPositive(index)"></i></span>
                  <el-divider direction="vertical" v-if="editForm.annexList.length > 1 && item !== editForm.annexList[editForm.annexList.length - 1]"></el-divider>
                </span>
              </span>
            </span>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'TemplateFileManagement',
  data() {
    return {
      api: this.$store.state.api,
      msForm: {
        templateSort: null,
        templateName: null,
      },
      dataList: [],
      currentPage: 1,
      pageSize: 18,
      total: 1,
      title: '添加模板文件',
      editForm: {
        rid: null,
        templateSort: null,
        templateName: null,
        annexList: [],
      },
      rules: {
        templateSort: [{ required: true, message: '请输入编码', trigger: ['change', 'blur'] }],
        templateName: [{ required: true, message: '请输入名称', trigger: ['change', 'blur'] }],
        annexList: [{ required: true, message: '请添加文件', trigger: ['change', 'blur'] }],
      },
    }
  },
  computed: {},
  watch: {},
  activated() { },
  created() { },
  mounted() {
    this.search(1)
  },
  methods: {
    //查询
    search(currentPage) {
      this.currentPage = currentPage
      this.$emit('loading', true)
      let data = {
        currentPage: currentPage,
        templateSort: this.msForm.templateSort,
        templateName: this.msForm.templateName,
      }
      this.$system.get(
        this.api + '/oh/repository/getFileTemplateList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.dataList = data.fileTemplateList
            this.total = data.fileTemplateCount
            this.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        this.error
      )
    },
    //删除
    del(row) {
      let data = {
        rid: row.rid,
      }
      this.$system.postJson(
        this.api + '/oh/repository/deleteFileTemplate-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.search(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    //添加
    add() {
      this.title = '添加模板文件'
      this.$refs.msDialog.show(true)
    },
    //编辑
    edit(row) {
      this.title = '编辑模板文件'
      let data = {
        rid: row.rid,
      }
      this.$system.get(
        this.api + '/oh/repository/getFileTemplate-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$refs.msDialog.show(true)
            this.editForm.rid = data.fileTemplate.rid
            this.editForm.templateSort = data.fileTemplate.templateSort
            this.editForm.templateName = data.fileTemplate.templateName
            this.editForm.annexList = []
            this.editForm.annexList.push({
              fileName: data.fileTemplate.templateName,
              fileIconShow: this.dealfilePrefix(data.fileTemplate.filePath),
              filePath: data.fileTemplate.filePath,
              check: false,
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        this.error
      )
    },
    submitVal() {
      var filePath = null
      if (this.$zwxBase.verifyIsNotBlank(this.editForm.annexList)) {
        filePath = this.editForm.annexList[0].filePath
      }
      this.$emit('loading', true)
      let data = {
        rid: this.editForm.rid,
        templateSort: this.editForm.templateSort,
        templateName: this.editForm.templateName,
        filePath: filePath,
      }
      this.$system.postJson(
        this.api + '/oh/repository/addOrModifyFileTemplate-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.$refs.msDialog.show(false)
            this.search(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    //提交
    submit() {
      this.$refs['editForm'].validate(valid => {
        if (valid) {
          this.submitVal()
        }
      })
    },
    //取消
    cancelDialog() {
      this.$refs.msDialog.show(false)
    },
    closeDialog() {
      ; (this.editForm.rid = null), (this.editForm.templateSort = null), (this.editForm.templateName = null), (this.editForm.annexList = [])
    },
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      console.log(fileList, index)
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    clickPositive(showList) {
      this.showList = showList
      showList.forEach(item => {
        let flag = this.editForm.annexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.editForm.annexList.push({
          fileName: item,
          fileIconShow: this.dealfilePrefix(item),
          check: false,
        })
      })
      this.generateFilesUid();
    },
    clickPositiveSuccess(fileList) {
      let fileExpList = this.editForm.annexList
      if (this.$zwxBase.verifyIsNotBlank(fileExpList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in fileExpList) {
          for (let j in fileList) {
            if (fileExpList[i].fileName === fileList[j].fileName) {
              fileExpList[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      this.editForm.annexList = fileExpList
      this.$forceUpdate();
      //this.submitVal()
    },
    //文件的UID
    generateFilesUid() {
      this.$emit('loading', true)
      let data = {
        folder: '/template',
      }
      this.$system.post(this.api + '/zky/employer/generateFilesUidOperations-1', data, true, true, data => {
        if (data.type === '00') {
          this.$refs.msPositive.submit(data.uid)
          this.$emit('loading', false)
        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    // 删除
    deletionPositive(index) {
      let fileName = this.editForm.annexList[index].fileName
      this.editForm.annexList.splice(index, 1)
      this.$refs.msPositive.deleteIndex(index)
      let fileList = this.$refs.msPositive.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.msPositive.deleteIndex(j)
            fileList = this.$refs.msPositive.showFileList || []
            continue
          }
        }
      }
    },
    clickError() {},
    mouseenter(index) {
      this.editForm.annexList[index].check = true
      this.$forceUpdate()
    },
    mouseleave(index) {
      this.editForm.annexList[index].check = false
      this.$forceUpdate()
    },
    dealfilePrefix(file) {
      let prefix = file.substring(file.indexOf('.'))
      let fileIconShow = ''
      if (prefix === '.pdf' || prefix === '.PDF') {
        fileIconShow = 'colorful chis-icon-colorful-pdf'
      } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
        fileIconShow = 'colorful chis-icon-colorful-word'
      } else if (prefix === '.png' || prefix === '.jpeg' || prefix === '.jpg' || prefix === '.JPG' || prefix === '.JPEG' || prefix === '.PNG') {
        fileIconShow = 'colorful chis-icon-colorful-jpg'
      }
      return fileIconShow
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  width: 512px !important;
  min-width: 512px !important;
}
</style>

<style lang="less"></style>
